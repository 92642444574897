import * as React from "react";
import image from '../../images/hurricane.png';
import image2 from '../../images/hurricane2.png';
import image3 from '../../images/hurricane3.png';
import Project from '../../Layouts/project';


// markup
const BC = () => {
  return (
    <Project 
      title="The Wake of the Storm" 
      images={[image, image2, image3]}
      link="https://agitated-edison-2d4a63.netlify.app/"
      description="A map-based visualization of power grid failure and recovery during major hurricanes built with D3. My final project in a visualization course, created in collaboration with two electrical engineers." 
      />)
}

export default BC
